import React from 'react';
import { Typography } from '../Typography';

interface IPostProps {
  title: string;
  date: Date;
  body: string | JSX.Element | JSX.Element[];
  featuredImage?: { url: string; altText: string };
}

export const Post = (props: IPostProps) => {
  const { title, date, body, featuredImage } = props;

  return (
    <div>
      <article>
        <div>{title}</div>
        <div>
          {date.toLocaleString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </div>
        {featuredImage && (
          <img src={featuredImage.url} alt={featuredImage.url} />
        )}
        <Typography>
          {body}
        </Typography>
      </article>
    </div>
  );
};
